import { createMuiTheme } from '@material-ui/core'

const { REACT_APP_ENV } = process.env
export const debug = REACT_APP_ENV !== 'production'
export const apiUrl = 'https://ardent-api.ardentlabs.io'

// export const apiUrl = debug
//   ? 'https://ardent-api-next.ardentlabs.io'
//   : 'https://ardent-api.ardentlabs.io'

export const AUTH_NET_API_URL = debug
  ? 'https://apitest.authorize.net/xml/v1/request.api'
  : 'https://api.authorize.net/xml/v1/request.api'
export const AUTH_NET_API_NAME = debug ? '5X9p2aLm' : '88kstjx5FXT'
export const AUTH_NET_TRANSACT_KEY = debug
  ? '3ET228hec8Apa39c'
  : '3V5ykZY2A9t26qN8'

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#1976d2', //'#00adef',
    },
    contrastThreshold: 3.1,
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
  },
})

export const EARLY_BIRD = new Date() < new Date('2025-05-19T08:00:00.000Z')

export const isMobile = window.innerWidth <= 768

//list of pathnames that do not need the header
export const doNotShowHeader = [
  '/',
  '/login',
  '/family',
  '/admin',
  '/instructor',
  '/recovery',
  '/changepassword',
  '/guestLogin',
  '/join',
  '/landing',
]

//catalog configs
export const YEAR = 2025
export const QUARTER = 'Spring'
export const DURATION = 'March 3, 2025 - May 11, 2025'
export const APCOURSE = [
  'B411D',
  'C411D',
  'N411D',
  'N421D',
  'N210D',
  'N220D',
  'M111D',
  'M112D',
  'P208D',
  'P209D',
]

export const RESEARCHCOURSE = ['A309A', 'A309B', 'A409A', 'A409B']

export const imagePlaceholder =
  'https://www.nomadfoods.com/wp-content/uploads/2018/08/placeholder-1-e1533569576673-1200x1200.png'
